/* .inner{ position: relative; height: auto; } */

.report-style-class-newreport {
    /* height: 464px; */
    /* width:1280px; */
    /* margin-left: -10px;
    margin-top: -90px;
    z-index: -1; */
    /* min-width: 80vw;
    position: absolute;
    top: 3vh; */
}

.report-style-class-newreport iframe{
    border:0px;
}

.report-style-class-newreport iframe body #pbiAppPlaceHolder .mainContent.title{
    display: none;
}

.report-style-class-search{
    z-index: -10;
}

.menuItem{
    background-color: white;
    color: red;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 8px;
    background-color: #f44336;
    color: white;
  }


.breadcrumbs{
    font-weight:500 ;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.subdiv{
padding: 5px;
}