/* Style for the tooltip itself */
.rtl {
  background: #333;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  max-width: 300px; /* Adjust as needed */
}

/* Style for the tooltip arrow/triangle */
.rtl::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #333 transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}
