.report-style-class-report {
    height: 93vh;
    /* width: 90vw; */
}

.menuItem{
    background-color: white;
    color: red;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 8px;
    background-color: #f44336;
    color: white;
  }